.footer-container{
   
    height:auto;
    background-color:#fff;
    box-shadow: 0 -5px 20px rgb(128, 128, 128);
    
}

.footer-columns{
    padding: 2rem 2rem;
    display: flex;
    justify-content: space-between;
}

.copyrights{
    display: flex;
    justify-content: center;
    padding: 1rem;
    color: #000000;
}

.moblogo{
    height: 40px;
    width: auto;
}

.column1{
   
    font-size: 12px;
    
   
}
.column1 p{
    padding-top: 10px;
    font-weight: 700;
    color: rgb(120, 120, 120);
    text-align: left;
}