@import url('https://fonts.googleapis.com/css2?family=Crete+Round:ital@1&family=Inter:wght@100..900&display=swap');

.bgimage {
  background-image: url("../../images/Rectangle 6.png");
  background-repeat: no-repeat;
  background-size: 100%;
 background-position: top center; 
  padding-top: 0px;
  left: 0;
  width: "100%";
  height: "auto";
 position: relative;
  
    
}



.contentcontainer{
  display: flex;
   padding: 2rem ;
   align-items: center;
   justify-content: space-between;
}

.content-left,.content-right{
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.content-right img {
  padding-left: 5rem;
  padding-top: calc(3rem + 2vh); /* Responsive padding */
  max-width: 100%; /* Ensure image doesn't exceed container width */
}

.content-left h1{
  font-size: 60px;
  font-family: "Crete Round", serif;
  font-weight: 400;
  letter-spacing: 5px;
}

.content-left h3{
  font-size: 15px;
  padding-top: 40px;
  font-weight: 500;
  
}

.partner-heading{
  padding:2px;
  font-family: "Crete Round", serif;
  font-weight: 300;
}
.partner-buttons{
    padding-top: 20px ;
}
.partner-buttons ul{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: auto;
  width: auto;
}

.partner-buttons ul  {
  transition: transform 0.3s ease-in-out; 
}

.partner-buttons ul :hover {
  transform: scale(0.98); 
  cursor: pointer;
}
.partner-buttons h2{
  font-size: 20px;
  display: flex;
  font-family: "Crete Round", serif;
  font-weight: 300;
  justify-content: center;
}
.partner-container{
  padding: 2rem;
}
.partner-container p{
  font-size: calc(1.5vh + 0.5rem);
  font-weight: 500;
  padding: 2rem 10rem;
  text-align: center;
  
}


