.Psychologist-image {
    background-image: url("../../images/Rectangle\ 54.png");
    background-repeat: no-repeat;
    background-size: 100%;
   background-position: top center; 
    padding-top: 0px;
    left: 0;
    width: "100%";
    height: "auto";
   position: relative;
  }
  
  
  
  .Psychologist-hero .contentcontainer{
    display: flex;
     padding: 2rem ;
     align-items: center;
     justify-content: space-between;
  }
  
  .Psychologist-hero .content-left,.content-right{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
 .Psychologist-hero .content-right img{
    margin-left: 2rem;
    padding-top: 3rem;
    padding-left: 6rem;
    background-size: contain;
  }
  
  .Psychologist-hero .content-left h1{
    font-size: 40px;
    font-family: "Crete Round", serif;
    font-weight: 400;
    letter-spacing: 2px;
  }
  
  .Psychologist-hero .content-left h3{
    font-size: 30px;
    padding-top: 40px;
    font-weight: 500;
    letter-spacing: 5px;
    
  }

 
  .Psychologist-hero .content-left-bottom,.content-right-bottom{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .Psychologist-hero .content-right-bottom img{
   
    padding-top: 3rem;
    padding-left: 12rem;
    background-size: contain;
  }
  
 
  
  .Psychologist-hero .content-left-bottom h3{
    font-size: 20px;
    
    font-weight: 500;
    letter-spacing: 2px;
    
  }
  .call{
    height: 400px;
    text-decoration: none;
    
  
   
  }

  .call .contact-button {
    display: flex;
   justify-content: center;
   margin-top:50px ;
   width: 150px;
   padding-top: 50px;
   background-color: rgb(0, 0, 98);
   text-decoration: none;
   color: aliceblue;
   font-weight: 500;
   padding: 10px 20px; 
  border: none; 
  border-radius: 20px; 
  cursor: pointer;
  margin-left: auto; 
  margin-right: auto;
  font-size: 20px;

  }

  .call .call-text {
    
   
    font-weight: 500;
    padding-top: 80px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
