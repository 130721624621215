.sol-container{
    padding: 2rem;
}



.sol-container {
    padding: 2rem;
}

.sol-heading {
    padding: 10px;
    font-family: "Crete Round", serif;
    font-weight: 300;    
}

.sol-box {
    display: flex;
    justify-content: space-between;
    margin: 10px;
    padding: 10px;
}

.sol-box-child {
    margin: 30px ;
    
    
    text-align: center; }

.sol-box-child img {
    margin: 0 auto; 
}


.box-content {
    color: rgb(0, 0, 119);
    text-align: center;
    padding-top: 10px;

}

.sol-box-child p {
    font-size: 12px;
}

.sol-heading{
    padding:10px;
    font-family: "Crete Round", serif;
    font-weight: 300;    
}

.sol-box-child{
    padding: 10px;
}
.sol-box-child h5,p{
    color: rgb(0, 0, 119);
    text-align: center;
}
.sol-box-child p {
    font-size: 12px;
}
