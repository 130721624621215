.contactbg {
    background-image: url("../../images/Rectangle 51.png");
    background-repeat: no-repeat;
    background-position: top center; /* Adjusted background-position to top */
    background-size: cover;
    left: 0;
    width: 100%;
    height: 100vh;
    top: 0;
    position: relative;
    
}

.contact-content {
   padding-top: 5rem;
}

.contact-content h1 {
    font-family: "Crete Round", serif;
    font-weight: 300;
    display: flex;
    justify-content: center;
}

.contact-content p {
    display: flex;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    padding: 2rem ;
    justify-content: center;
    text-align: center;
    
}
.contact-box {
    width: 50rem;
    height: auto;
    background-color: rgba(230, 230, 230, 0.6) ;
    opacity: 0.7;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 20px rgb(101, 101, 101);
}
.box-content {
    color: rgb(0, 0, 0);
    
    
    text-align: center;
    padding: 30px;

}
.box-content a{
    text-decoration: none;
    cursor: pointer;
    ;
    color: black;

}
.box-container {
    width: 80%;  /* Adjust for responsiveness */
    
    height: 200px;
    background-color: rgba(255, 255, 255, 0.555);
    padding: 40px;
    position: relative;
    margin: auto;
    box-shadow: 10px 10px 20px rgba(182, 182, 182, 0.384);
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .callicon h5, .watsapp-icon h5 {
    text-decoration: none;
    font-size: 20px;
    padding-top: 10px;
    color: rgb(70, 70, 70);
  }
  
  .callicon p, .watsapp-icon  p {
    font-size: 14px;  /* Increased for readability */
    text-align: center;
    padding-top: 20px;
  }
  
  .cicon, .waicon {
    width: 50px;
    height: 50px;
    position: relative;
  }
  

  .callicon,
.watsapp-icon {  /* Apply to both child divs */
  display: flex;  /* Make child divs flex containers */
  flex-direction: column;  /* Stack content vertically */
  align-items: center;  /* Center content horizontally */
  justify-content: center;  /* Center content vertically within child divs */
}