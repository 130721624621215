
*{
  margin: 0;
  padding: 0;
  
}

nav{
  z-index: 99;
  position: sticky;
  top:0;
  background-color: rgb(255, 255, 255);
  box-shadow:0  5px 10px rgb(218, 218, 218);
  
  
}
nav ul{
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
nav li{
  height: 50px;
  cursor: pointer;
}
nav a{
  height: 100%;
  padding: 0 30px;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: black;
  font-weight: 600;
  
}
.logo {
  font-weight: bold;
  font-style: italic;
}

nav li:first-child{
  margin-right: auto;
}
.sidebar{
  position: fixed;
  top: 0; 
  right: 0;
  height: 100vh;
  width: 250px;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(12px);
  box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
  list-style: none;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.sidebar li{
  width: 100%;
}
.sidebar a{
  width: 100%;
}
.menu-button{
  display: none;
}
@media(max-width: 800px){
  .hideOnMobile{
    display: none;
  }
  .menu-button{
    display: block;
  }
}
@media(max-width: 400px){
  .sidebar{
    width: 100%;
  }
}